import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '../views/Landing.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import CookiePolicy from '../views/CookiePolicy.vue';
import Labs from '../views/Labs.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: LandingPage,
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/cookie-policy',
    name: 'cookiePolicy',
    component: CookiePolicy,
  },
  {
    path: '/business-card',
    redirect: '/',
  },
  {
    path: '/labs',
    name: 'labs',
    component: Labs,
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return document.querySelector(to.hash).scrollIntoView({ behavior: 'smooth' });
    }
    return window.scrollTo(0, 0);
  },
});

export default router;
