<template>
    <div>
        <MainSection></MainSection>
        <!-- <WhatWeOffer></WhatWeOffer> -->
        <Coworking />
        <DigitalMarketing />
        <Contacts />
    </div>
</template>

<script>
import MainSection from '@/components/LandingPage/MainSection.vue';
// import WhatWeOffer from '@/components/LandingPage/WhatWeOffer.vue';
import Coworking from '@/components/LandingPage/Coworking.vue';
// import Affiliates from '@/components/LandingPage/Affiliates.vue';
import Contacts from '@/components/LandingPage/Contacts.vue';

export default {
    name: 'LandingPage',
    components: { 
        MainSection, 
        Coworking,  
        Contacts, 
    },
};
</script>
