<template>
  <div class="mt-20 mb-20">
    <h1 class="font-extrabold text-5xl">Cookie Policy</h1>
    <p class="mt-10">Questa Cookie Policy descrive come TheGINLabs utilizza i cookie e altre tecnologie simili sul suo sito web.</p>

    <h2 class="font-extrabold mt-10">Cosa sono i cookie?</h2>
    <p class="ml-10 mt-3">I cookie sono piccoli file di testo che vengono memorizzati sul dispositivo dell'utente quando si visita un sito web. Questi file di testo contengono informazioni che possono essere lette dal sito web in seguito. <br> I cookie possono essere utilizzati per vari scopi, ad esempio per ricordare le preferenze dell'utente, per raccogliere informazioni sull'utilizzo del sito web, per personalizzare i contenuti e per mostrare annunci pubblicitari mirati.</p>
    
    <h2 class="font-extrabold mt-10">Tipi di cookie utilizzati da TheGINLabs</h2>
    <p class="ml-10 mt-3">TheGINLabs utilizza i seguenti tipi di cookie:</p>
    <ul class="list-disc ml-20 mt-3">
      <li><strong>Cookies tecnici:</strong> questi cookie sono necessari per il funzionamento del sito web. Essi consentono all'utente di navigare sul sito e di utilizzare le sue funzionalità. Senza questi cookie, alcune parti del sito potrebbero non funzionare correttamente;</li>
      <li><strong>Cookies di analisi:</strong> questi cookie vengono utilizzati per raccogliere informazioni sull'utilizzo del sito web, ad esempio sulle pagine visitate e sui messaggi di errore visualizzati. Questi cookie non raccolgono informazioni che identificano personalmente l'utente. Tutte le informazioni raccolte sono aggregate e anonime;</li>
      <li><strong>Cookies di terze parti:</strong> questi cookie sono utilizzati da terze parti per mostrare annunci pubblicitari mirati e per analizzare l'utilizzo del sito web. TheGINLabs non ha alcun controllo sui cookie di terze parti e non è responsabile per le informazioni raccolte da essi.</li>
    </ul>
    
    <h2 class="font-extrabold mt-10">Come disabilitare i cookie?</h2>
    <p class="ml-10 mt-3">L'utente può disabilitare i cookie utilizzando le impostazioni del proprio browser. Tuttavia, se l'utente disabilita i cookie, alcune parti del sito web potrebbero non funzionare correttamente. Per ulteriori informazioni su come gestire i cookie, l'utente può consultare le istruzioni fornite dal produttore del proprio browser.</p>
    
    <h2 class="font-extrabold mt-10">Modifiche alla Cookie Policy</h2>
    <p class="ml-10 mt-3">TheGINLabs si riserva il diritto di modificare la Cookie Policy in qualsiasi momento. Eventuali modifiche saranno pubblicate sul sito e gli utenti verranno informati tramite una comunicazione appropriata.</p>
    
    <h2 class="font-extrabold mt-10">Consenso all'utilizzo dei cookie</h2>
    <p class="ml-10 mt-3">Prima di utilizzare il sito web di TheGINLabs, l'utente deve fornire il proprio consenso all'utilizzo dei cookie. Questo avviene tramite una notifica che compare sulla homepage del sito. L'utente può accettare o rifiutare l'utilizzo dei cookie.</p>
    
    <h2 class="font-extrabold mt-10">Cookies di terze parti utilizzati da TheGINLabs</h2>
    <p class="ml-10 mt-3">TheGINLabs utilizza i seguenti cookie di terze parti sul suo sito web:</p>
    <table class="ml-10 border-separate border-spacing-2 border border-slate-400 mt-5 table-fixed">
      <thead>
        <tr class="text-center">
          <th class="border border-slate-300 px-10">Cookie</th>
          <th class="border border-slate-300 px-10">Fornitore</th>
          <th class="border border-slate-300 px-10">Scopo</th>
          <th class="border border-slate-300 px-10">Dati raccolti</th>
          <th class="border border-slate-300 px-10">Durata</th>
        </tr>
      </thead>
      <tbody>
        <tr >
          <td class="text-center px-10 border border-slate-300 px-10">Google Analytics</td>
          <td class="text-center px-10 border border-slate-300 px-10">Google</td>
          <td class="text-center px-10 border border-slate-300 px-10">Analisi del traffico</td>
          <td class="text-center px-10 border border-slate-300 px-10">Dati sull'utilizzo del sito web</td>
          <td class="text-center px-10 border border-slate-300 px-10">2 anni</td>
        </tr>
        <tr>
          <td class="text-center px-10 border border-slate-300">Facebook Pixel</td>
          <td class="text-center px-10 border border-slate-300">Facebook</td>
          <td class="text-center px-10 border border-slate-300">Mostrare annunci <br> pubblicitari mirati</td>
          <td class="text-center px-10 border border-slate-300">Dati sull'utilizzo del sito web e sulle interazioni con gli <br> annunci pubblicitari</td>
          <td class="text-center px-10 border border-slate-300">1 anno</td>
        </tr>
      </tbody>
    </table>

    <h2 class="font-extrabold mt-10">Come gestire i cookie di terze parti</h2>
    <p>TheGINLabs non ha alcun controllo sui cookie di terze parti utilizzati sul suo sito web. L'utente può gestire i cookie di terze parti modificando le impostazioni del proprio browser o utilizzando gli strumenti di gestione dei cookie forniti dai fornitori dei cookie stessi.</p>

    <h2 class="font-extrabold mt-10">Modifiche alla Cookie Policy</h2>
    <p class="ml-10 mt-3">TheGINLabs si riserva il diritto di modificare la Cookie Policy in qualsiasi momento. Eventuali modifiche saranno pubblicate sul sito e gli utenti verranno informati tramite una comunicazione appropriata.</p>

    <h2 class="font-extrabold mt-10">Contatti</h2>
    <p class="ml-10 mt-3">Per qualsiasi domanda o richiesta in merito alla privacy policy o alla cookie policy, o per esercitare i propri diritti in materia di protezione dei dati personali, gli utenti possono contattare TheGINLabs attraverso il form di contatto disponibile sul sito o tramite i seguenti recapiti:</p>
    <ul class="list-disc ml-20">
    <li>Indirizzo: SP72, 73042 Casarano (LE)</li>
    <li>Email: info@theginlabs.com</li>
    <!-- <li>Telefono: +39 1234567890</li> -->
  </ul>
  </div>
</template>
