<template>     

        <div 
            class="card-body  h-full w-[90%] mx-auto text-white hover:scale-105  shadow-xl ease-in-out duration-300 rounded-lg"
            :style="`background-color:${$props.planObject.color}`"
            :class="{ 
                        'bg-primary hover:bg-neutral-800' : $props.planObject.color===true,
                        'bg-neutral-800 hover:bg-primary' : $props.planObject.color===false 
                    }"
          
            >
            
            <font-awesome-icon :icon=$props.planObject.icon size="4x"></font-awesome-icon>
            <p class="font-sans font-black tracking-widest uppercase text-center text-3xl  ">{{$props.planObject.name}}</p>
            <p class="text-lg font-black text-center  text-lg">{{$props.planObject.text}}</p>

        </div>

</template>

<script>
export default {
    name: 'IconCard',
    props: {
        planObject: {
            name: '',
            icon: '',
            text: '',
            color: true,
        },
    },

    data() {
        return {
        };
    },
};
</script>
