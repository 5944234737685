import { createApp } from 'vue';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
/* import specific icons */
import { createImageKitVue } from "imagekit-vue3";

import { 
    // faUserSecret,
    faUser,
    faUsers,
    faMugHot,
    faNetworkWired,
    faPrint,
    faUserLock,
    faHeadphones,
    faBell,
    faPaperPlane,
    faCircleNodes,
    faHouseLaptop,
    faBullhorn,
    faDesktop,
    faTruckFast,
    faHeart,
    faComment,
    faCaretLeft,
    faCaretRight,
} from '@fortawesome/free-solid-svg-icons';
import {
    faSlideshare,
    faFacebookF,
    faTwitter,
    faInstagram,
    faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/css/main.css';

library.add(faUser);
library.add(faUsers);
library.add(faMugHot);
library.add(faNetworkWired);
library.add(faSlideshare);
library.add(faPrint);
library.add(faFacebookF);
library.add(faInstagram);
library.add(faTwitter);
library.add(faUserLock);
library.add(faHeadphones);
library.add(faBell);
library.add(faPaperPlane);
library.add(faCircleNodes);
library.add(faHouseLaptop);
library.add(faBullhorn);
library.add(faDesktop);
library.add(faTruckFast);
library.add(faHeart);
library.add(faComment);
library.add(faCaretLeft);
library.add(faCaretRight);
library.add(faFacebookF);
library.add(faInstagram);
library.add(faLinkedin);

const app = createApp(App);
app.use(store);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(createImageKitVue({
    publicKey: 'public_2vLV9Bi1mycAZ3XWiwQLIxjGhsU=',
    urlEndpoint: "https://ik.imagekit.io/z0nni0jpl", // Required. Default URL-endpoint is https://ik.imagekit.io/your_imagekit_id
    registerGlobalComponents: true, // optional. Default is false, this will register all ImageKitVue components globally
}));
app.use(router).mount('#app');
