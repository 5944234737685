export default {
    labs: [
        {
            name: "CHAT GPT - FROM ZERO TO HERO",
            images: [
                {
                    src: "tgl/ChatGPT.png",
                    alt: "Chat GPT",
                },
            ],
            caption: " Impara come utilizzare l'intelligenza artificiale per semplificare la tua vita quotidiana esplorando le sue applicazioni pratiche ed esaminandone i rischi e l'etica. ",
            date: '14.10.2023',
            price: "Free Entry",
        },
        {
            name: "BLOCK CHAIN E BITCOIN",
            images: [
                {
                    src: "tgl/Blockchain.png",
                    alt: "Blockchain",
                },
            ],
            caption: "Esplora il mondo affascinante di Blockchain e Bitcoin con noi! Scopri cosa si nasconde dietro il mondo delle criptovalute e le loro origini",
            date: '25.11.2023',
            price: "Free Entry",
        },
    ],
};
