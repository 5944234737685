<template>
  <div>
      <!-- <WhatWeOffer></WhatWeOffer> -->
      <Presentation />
      <LabsList />
  </div>
</template>

<script>
import Presentation from '@/components/Labs/Presentation.vue';
import LabsList from '@/components/Labs/LabsList.vue';

export default {
  name: 'Labs',
  components: { 
    Presentation,
    LabsList,
  },
};
</script>
