<template>
    <div class="grid grid-cols-12 mb-10">
        <div class="col-span-full mb-10">
            <h1 class="leading-none text-center">
                <span
                    class="font-sans font-black tracking-widest uppercase text-center 
                    text-[2.7rem] md:text-[5.2rem] lg:text-[7rem]"
                >
                    dove le idee
                </span><br/>
                <span
                    class="font-sans font-black tracking-widest uppercase text-center
                    text-[1.9rem] md:text-[3.8rem] lg:text-[5rem]"
                >
                    prendono forma
                </span>
            </h1>
        </div>
        <div class="col-span-full mt-10 mx-3 md:mx-0">
            <div class="card">
                <div class="carousel carousel-center p-4 space-x-4 bg-base-200 rounded-box" @navigation-click="navigationHandler">
                    <div class="carousel-item">
                        <IKImage
                            class="snap-start max-h-96"
                            alt="Pizza"
                            path="tgl/TGLMW.jpeg"
                            :lqip="{active:true}"
                            width="260"
                            height="390"

                        />
                    </div> 
                    <div class="carousel-item">
                        <IKImage
                            class="max-h-96"
                            alt="Pizza"
                            path="tgl/TGLWall.jpeg"
                            :lqip="{active:true}"
                            width="590"
                            height="390"

                        />
                    </div> 
                    <div class="carousel-item">
                        <IKImage
                            class="max-h-96"
                            alt="Pizza"
                            path="tgl/TGLMW2.jpeg"
                            :lqip="{active:true}"
                            width="260"
                            height="390"

                        />
                    </div> 
                    <div class="carousel-item">
                        <IKImage
                            class="max-h-96"
                            alt="Pizza"
                            path="tgl/TGLGW.jpeg"
                            :lqip="{active:true}"
                            width="686"
                            height="390"

                        />
                    </div> 
                    <div class="carousel-item">
                        <IKImage
                            class="max-h-96"
                            alt="Pizza"
                            path="tgl/TGLPod.jpeg"
                            :lqip="{active:true}"
                            width="260"
                            height="390"

                        />
                    </div> 
                    <div class="carousel-item">
                        <IKImage
                            class="max-h-96"
                            alt="Pizza"
                            path="tgl/TGLMPP.jpeg"
                            :lqip="{active:true}"
                            width="260"
                            height="390"

                        />
                    </div>       
                </div>
                <div class="grid grid-cols-2 mt-6 ">
                    <button class="btn-primary ml-auto mr-10 rounded-lg text-left text-white w-fit px-10 col-span-1" @click="changeSlide(-1)">
                        <font-awesome-icon icon="fa-solid fa-caret-left" size="4x"></font-awesome-icon>
                    </button>
                    <button class="btn-primary rounded-lg ml-10 text-white w-fit px-10 col-span-1 " @click="changeSlide(1)">
                        <font-awesome-icon icon="fa-solid fa-caret-right" size="4x"></font-awesome-icon>
                    </button>         
                </div>
                   
            </div>
            <div class="">
                <div class="grid grid-cols-1 lg:grid-cols-3 mt-10 ">

                    <div
                            v-for="ic,i in iconCard" :key="i"
                            class="cols-1 mb-10"
                        >
                            <IconCard :planObject=ic></IconCard>
                        </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import { ServicesData } from '@/data/price-and-services';
// import ChatCard from '../common/ChatCard.vue';
import IconCard from '../common/IconCard.vue';

export default {
    name: 'MainSection',
    data() {
        return {
            mex: [
                
                {
                    name: "Coworker",
                    text: "Ciao, chi sei?",
                    img: require("@/assets/imgs/people3.jpg"),
                    speaker: 1,
                },
                {
                    name: "TheGINLabs",
                    text: "Ciao, siamo un giovane spazio di coworking, situato nel cuore di Casarano",
                    img: require("@/assets/imgs/tgl-o-sm.png"),
                    speaker: 2,
                },
                {
                    name: "Coworker",
                    text: "E cosa fate?",
                    img: require("@/assets/imgs/people3.jpg"),
                    speaker: 1,
                },
                {
                    name: "TheGINLabs",
                    text: "Vogliamo offrire un'esperienza di lavoro unica e flessibile per freelancer, start-up e piccole imprese",
                    img: require("@/assets/imgs/tgl-o-sm.png"),
                    speaker: 2,
                },
                {
                    name: "Coworker",
                    text: "In che senso un esperienza di lavoro unica?",
                    img: require("@/assets/imgs/people3.jpg"),
                    speaker: 1,
                },
                {
                    name: "TheGINLabs",
                    text: "Abbiamo creato un'atmosfera stimolante e collaborativa dove le persone possono lavorare, incontrarsi e crescere insieme",
                    img: require("@/assets/imgs/tgl-o-sm.png"),
                    speaker: 2,
                },
                {
                    name: "Coworker",
                    text: "Sembra interessante... ma io sono ancora uno studente...",
                    img: require("@/assets/imgs/people3.jpg"),
                    speaker: 1,
                },
                {
                    name: "TheGINLabs",
                    text: "Nessun problema, il nostro spazio è aperto a tutti, anche a chi si sta formando e vuole un posto stimolante solo per studiare",
                    img: require("@/assets/imgs/tgl-o-sm.png"),
                    speaker: 2,
                },
                {
                    name: "TheGINLabs",
                    text: "Dai un'occhiata ai nostri piani e vieni a trovarci...",
                    img: require("@/assets/imgs/tgl-o-sm.png"),
                    speaker: 2,
                },
                
            ],
            iconCard: [
                {
                    name: "Coworking Space",
                    text: "Un ambiente che si adatta alle tue esigenze di business, design e produttività",
                    icon: "fa-solid fa-house-laptop",
                    color: true,
                },
                {
                    name: "TheGINNet",
                    text: "Unisciti alla nostra rete di professionisti",
                    icon: "fa-solid fa-circle-nodes",
                    color: false,
                },
                {
                    name: "Digital Marketing",
                    text: "Tu pensa al tuo business, al resto ci pensiamo noi",
                    icon: "fa-solid fa-bullhorn",
                    color: true,
                },
            ],
            ...ServicesData,
        };
    },
     components: { IconCard },
     methods: {
        changeSlide(delta) {
        const carousel = this.$el.querySelector(".carousel");
        const width = carousel.offsetWidth;
        carousel.scrollTo(carousel.scrollLeft + width * delta, 0);
        },
    },
};
</script>

<style>
div.full-width-text {
      text-align: justify;
    }

div.full-width-text:after {
    content: "";
    display: inline-block;
    width: 100%;
}

.bg {
    background-image:url('@/assets/imgs/muschio.jpg')  
}
</style>
