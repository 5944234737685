<template>
  <div class="grid grid-cols-10 bg-base-100 min-h-screen">
    <div class="col-span-full lg:col-span-8 lg:col-start-2 xl:col-span-8 xl:col-start-2 2xl:col-span-6 2xl:col-start-3">
      <Navbar></Navbar>
    </div>
    <div class="col-span-full lg:col-span-10 xl:col-span-8 xl:col-start-2 2xl:col-span-6 2xl:col-start-3">
      <router-view/>
    </div>
    <div class="col-span-full">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Navbar from './components/common/Navbar.vue';
import Footer from './components/common/Footer.vue';

export default {
  name: 'App',
  components: { Navbar, Footer },
};
</script>
