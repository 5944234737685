<template>
    <div>
        <div class="w-full px-10 py-10 text-black justify-self-start">                 
            <div id="what" class="col-span-full lg:col-span-1 lg:px-5 py-5">
                <h2
                    class="font-sans text-left md:text-2xl lg:text-4xl font-bold uppercase"
                    >
                        Prossimi appuntamenti
                </h2>
            <hr style=" border: 2px solid #ee7214; margin-top:0.5rem">
            </div>
        </div>
        <div id="Labs" class="flex flex-col lg:flex-row justify-items-center items-center my-4 w-full">
            <div  class="group 
                w-4/6
                lg:w-1/2
                xl:w-1/3
                ml-10
                m-2
                px-2 my-5 lg:my-2 " v-for="s,i in labs" :key="i">
                <EventCard :labsObject="s"></EventCard>
            </div>
        </div>
    </div>
</template>

<script>
import EventCard from '../common/EventCard.vue';
import LabsData from '../../data/labs';

export default {
    name: "LabsPage",
    data() {
        return {
            ...LabsData,
        };
    },
    components: { EventCard },
};
</script>
