<template>
    <div id="LabsPres" class=" w-full flex flex-col 
                            lg:flex-row  mt-10 rounded-3xl bg-primary px-10 py-10 text-white">            
        <div id="what" class="w-full lg:w-1/2  text-white lg:px-5  py-5">
            <!-- Labs Description -->
            <h2
                class="text-white font-sans font-black text-left tracking-widest 
                text-[2.6rem] md:text-[5.2rem] lg:text-[7rem]"
                >
                    LABs
                </h2>
            <p class="prose text-white lg:border-l-4 border-black px-3 py-1 my-3">
                <span class="text-lg">
                    La sezione <span class="font-extrabold">LABS</span> di TheGINLabs nasce con lo scopo di dare uno spazio in cui confrontarsi,
                    crescere e soprattutto accrescere le proprie conoscenze. <br> Tra <span class="font-extrabold">seminari</span> e <span class="font-extrabold">corsi</span> scopriremo gli argomenti
                    tecnologi ( e non solo) più interessanti del momento ed impareremo a conoscerli ed usarli. <br>
                    <span class="font-extrabold">Unisciti a noi</span> e scopri il modo dei LABS
                </span>
            </p>
            <!-- Labs Description -->
        </div>
        <div class="w-full lg:w-1/2 flex flex-row justify-center">
            <video autoplay muted loop class="object-center object-cover rounded-2xl " width="350" height="400">
                <!-- <IKImage
                    class="object-center object-cover rounded-2xl "
                    alt="TheGINLabs"
                    path="tgl/TGLIns.jpeg"
                    :lqip="{active:true}"
                    width="350"
                    height="400"

                /> -->
                <source src="https://ik.imagekit.io/z0nni0jpl/tgl/Reel_Lite.mp4?updatedAt=1701113591689" type="video/mp4">
            </video>
        </div>
    </div>
</template>

<script>

export default {
    name: "LabsPage",
    data() {
        return {
            
        };
    },
    components: { },
};
</script>
