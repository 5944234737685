<template>
        <div class="w-full h-full mb-3 hover:pt-10 hover:origin-center border-4 border-primary hover:-translate-y-6 ease-in duration-300" 
            :style="`background-color:${$props.planObject.color}; color:${$props.planObject.txtcolor}; border-color:${$props.planObject.bcolor} `" 
            style=" padding:2rem; border-radius: 10%;">
        <div class="btn-group btn-group-toggle pb-5 hidden md:block" data-toggle="buttons" >
            <label 
                class="btn btn-secondary pl-2 font-extrabold" 
                v-for="s,i in $props.planObject.types" :key="i"
                :class="{
                    [$props.planObject.btnSelect]: i===plan,
                    [$props.planObject.btn] : i!==plan,
                }"  
            >
                <input type="radio"
                class="opacity-0  text-center"
                v-model="plan" name="a" :id="s.name" :value="i" autocomplete="off" >
                 {{s}}
            </label> 
        </div>
        <div v-if="$props.planObject.types" class="md:hidden">
            <label></label>
            <div class="btn-group btn-group-toggle pb-5 text-black font-extrabold" data-toggle="buttons" >
                <select v-model="plan" class="rounded-lg p-2">
                    <option  v-for="s,i in $props.planObject.types" :key="i" name="a" :id="s.name" :value="i" autocomplete="off">{{s}}</option>
                </select>
            </div>
        </div>
        
        <h3 class="text-xl md:text-2xl lg:text-3xl font-black mb-1 uppercase">
            {{ $props.planObject.name }}
        </h3>
        <h4 class="text-left">
            
            <span class="font-black text-4xl">
                {{ $props.planObject.price[plan] }} €
            </span>
            <span class="font-extrabold text-2xl">
                / {{ $props.planObject.period[plan] }}
            </span>
        </h4>
        <p class="text-white text-base font-extralight">
            {{ $props.planObject.description }}
        </p>
        <h5 class="text-lg font-extrabold uppercase mt-3 px-1 mb-1">Servizi inclusi</h5>
        <div class="grid grid-cols-1">
            <div class="col-span-1 mx-1 mb-1" v-for="s in $props.planObject.services" :key="s.id">
                    <p>
                        <font-awesome-icon :icon="s.icon"></font-awesome-icon>
                        <span > &nbsp;{{ s.name }}</span>
                    </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PricePlanCard',
    props: {
        planObject: {
            name: '',
            briefDescription: '',
            color: '',
            price: '',
            period: '',
            duration: '',
            services: [],
        },
    },

    data() {
        return {
            plan: 0,
        };
    },
};
</script>
