<template>
    <div class="navbar bg-base-100 rounded-2xl my-4 px-3 py-3">
        <div class="navbar-start">
            <div class="dropdown">
                <label tabindex="0" class="btn btn-ghost lg:hidden">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                </label>
                <ul tabindex="0" class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                    <li class="min-w-full">
                        <router-link  :to="{name: 'home', hash: '#spaces'}" >
                            Spazi
                        </router-link>
                    </li>
                    <li class="min-w-full">
                        <router-link :to="{name: 'home', hash: '#services'}">
                            Servizi
                        </router-link>
                    </li>
                    <li class="min-w-full">
                        <router-link :to="{name: 'home', hash: '#prices'}">
                            Listino
                        </router-link>
                    </li>
                    <li class="min-w-full">
                        <router-link :to="{name: 'labs'}">
                            LABs
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'home', hash: '#contacts'}">
                            Contatti
                        </router-link>
                    </li>
                </ul>
            </div>
            <router-link class="btn btn-ghost normal-case text-xl" :to="{name: 'home'}">
                <img
                    class="max-h-full" 
                    :src="require('@/assets/imgs/tgl-logo-h.png')" alt="TheGINLabs"
                >
            </router-link>  
        </div>
        <div class="navbar-center hidden lg:flex">
            <ul class="menu menu-horizontal px-1">                    
                <li>
                    <router-link  :to="{name: 'home', hash: '#spaces'}" >
                        Spazi
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name: 'home', hash: '#services'}">
                        Servizi
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name: 'home', hash: '#prices'}">
                        Listino
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name: 'labs'}">
                        LABs
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name: 'home', hash: '#contacts'}">
                        Contatti
                    </router-link>
                </li>
            </ul>
        </div>
        <!-- <div class="navbar-end">
            <a class="btn">Get started</a>
        </div> -->
    </div>
</template>

<script>
export default {
    name: 'Navbar',
};
</script>
