const ServicesData = {
    services: {
        single_desk: {
            name: "Postazioni",
            icon: "fa-solid fa-house-laptop",
        },
        print_and_scan: {
            name: "Stampa",
            icon: "fa-solid fa-print",
        },
        chill_area: {
            name: "Chilling Area",
            icon: "fa-solid fa-mug-hot",
        },
        network: {
            name: "Fibra Wi-fi",
            icon: "fa-solid fa-network-wired",
        },
        privacy_pod: {
            name: "Privacy Pod",
            icon: "fa-solid fa-headphones",
        },
        meeting_room: {
            name: "Meeting room",
            icon: "fa-brands fa-slideshare",
        },
        monitor: {
            name: "Monitor",
            icon: "fa-solid fa-desktop",
        },
        post_office: {
            name: "Fermo Posta",
            icon: "fa-solid fa-truck-fast",
        },

    },
};

const PlansData = {
    pricing_plans: {
        thinker1: {
            name: "Thinker",
            briefDescription: "",
            fullDescription: "",
            types: ["Singolo", "Carnet 10"],
            price: ["8,00", "72,00"],
            period: ["ingresso", "10 ingressi"],
            color: "#ee7623",
            btn: " bg-primary border-primary border-2 border-white text-white hover:bg-secondary ",
            btnSelect: "bg-white border-white border-none ",
            txtcolor: "#fff",
            bcolor: "ee7623",
            duration: "1 ingresso",
            services: [
                ServicesData.services.single_desk,
                ServicesData.services.chill_area,
            ],
        },
        
        innovator1: {
            name: "Innovator",
            briefDescription: "",
            fullDescription: "",
            types: ["Singolo", "Carnet 10"],
            price: ["12,00", "108"],
            period: ["ingresso", "10 ingressi"],
            duration: "1 ingresso",
            color: "#ee7623",
            bcolor: "ee7623",
            txtcolor: "#fff",
            btn: "bg-primary bg-primary border-primary border-2 border-white text-white hover:bg-secondary",
            btnSelect: "bg-white border-white border-none",
            services: [
                ServicesData.services.single_desk,
                ServicesData.services.print_and_scan,
                ServicesData.services.privacy_pod,
                ServicesData.services.chill_area,
            ],
        },
        
        innovator_plus_month: {
            name: "Innovator+",
            briefDescription: "",
            fullDescription: "",
            types: ["Mensile", "Semestrale", "Annuale"],
            price: ["250,00", "1420,00", "2820,00"],
            period: ["mese", "6 mesi", "anno"],
            color: "#ee7623",
            bcolor: "ee7623",
            txtcolor: "#fff",
            btn: "bg-primary bg-primary border-primary border-2 border-white text-white hover:bg-[#da7a3]",
            btnSelect: "bg-white border-white border-none",
            duration: "1 mese",
            services: [
                ServicesData.services.single_desk,
                ServicesData.services.network,
                ServicesData.services.privacy_pod,
                ServicesData.services.print_and_scan,
                ServicesData.services.meeting_room,
                ServicesData.services.chill_area,
            ],
        },
        
        half_day: {
            name: "Half Day",
            briefDescription: "",
            fullDescription: "",
            price: "8,00",
            period: ["metà giornata"],
            duration: "metà giornata",
            color: "#ffffff",
            bcolor: "#ffffff",
            txtcolor: "#000000",
            btn: "bg-primary bg-primary border-primary border-2 border-white text-white hover:bg-secondary",
            btnSelect: "bg-primary border-white border-none",
            services: [
                ServicesData.services.single_desk,
                ServicesData.services.network,
                ServicesData.services.chill_area,
            ],
        },
        meeting: {
            name: "Meeting Room",
            briefDescription: "",
            fullDescription: "",
            price: "8,00",
            period: ["ora"],
            color: "#000000",
            txtcolor: "#fff",
            bcolor: "#fff",
            duration: "1 ora",
            btn: "bg-primary bg-primary border-primary border-2 border-white text-white hover:bg-secondary",
            btnSelect: "bg-white border-white border ",
            services: [
                ServicesData.services.network,
                ServicesData.services.chill_area,
                ServicesData.services.monitor,
            ],
        },
        
    },
};

export { ServicesData, PlansData };
