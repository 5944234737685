<template>
<div class="mt-20 mb-20">
  <h1 class="font-extrabold text-5xl">Informativa Privacy</h1>

  <h2 class="font-bold mt-10 mb-3">1. Informazioni personali raccolte</h2>
  <p class="ml-10">TheGINLabs potrebbe raccogliere le seguenti informazioni personali degli Utenti attraverso il Sito e il form di contatto:</p>
  <ul class="list-disc ml-20">
    <li>Nome e cognome;</li>
    <li>Indirizzo email;</li>
    <li>Numero di telefono;</li>
    <li>Altro tipo di informazioni che l'Utente può fornire volontariamente nella sezione "messaggio" del form di contatto.</li>
  </ul>

  <h2 class="font-extrabold mt-10 mb-3">2. Finalità e base giuridica del trattamento</h2>
  <p class="ml-10 ">TheGINLabs raccoglie e tratta le informazioni personali degli Utenti per le seguenti finalità:</p>
  <ul class="list-disc ml-20">
    <li>Per fornire informazioni sull'attività di coworking di TheGINLabs e rispondere alle richieste degli Utenti attraverso il form di contatto;</li>
    <li>Per inviare agli Utenti comunicazioni promozionali e newsletter riguardanti l'attività di coworking di TheGINLabs solo previo consenso dell'Utente.</li>
  </ul>
  <p class="ml-10">La base giuridica per il trattamento delle informazioni personali degli Utenti è rappresentata dal consenso degli Utenti stessi, fornito attraverso l'invio del modulo di contatto sul Sito.</p>

  <h2 class="font-extrabold mt-10 mb-3">3. Conservazione delle informazioni personali</h2>
  <p class="ml-10">Le informazioni personali degli Utenti saranno conservate solo per il tempo necessario al raggiungimento delle finalità sopra indicate o per il tempo necessario a rispettare gli obblighi di legge.<br> In ogni caso, TheGINLabs si impegna a cancellare le informazioni personali degli Utenti non appena non siano più necessarie per le finalità sopra indicate.</p>

  <h2 class="font-extrabold mt-10 mb-3">4. Diritti degli Utenti</h2>
  <p class="ml-10">Gli Utenti hanno il diritto di:</p>
  <ul class=" list-disc ml-20">
    <li>Accedere alle informazioni personali trattate da TheGINLabs;</li>
    <li>Chiedere la correzione di eventuali informazioni personali inesatte o incomplete;</li>
    <li>Chiedere la cancellazione delle proprie informazioni personali;</li>
    <li>Chiedere la limitazione del trattamento delle proprie informazioni personali;</li>
    <li>Opporsi al trattamento delle proprie informazioni personali per motivi legittimi;</li>
    <li>Ricevere le proprie informazioni personali in un formato strutturato, di uso comune e leggibile da dispositivo automatico.</li>
  </ul>
  <p class="ml-10">Gli Utenti possono esercitare i propri diritti inviando una richiesta a TheGINLabs attraverso il form di contatto disponibile sul Sito.</p>

  <h2 class="font-extrabold mt-10 mb-3">5. Divulgazione delle informazioni personali</h2>
  <p class="ml-10">TheGINLabs potrebbe divulgare le informazioni personali degli Utenti nei seguenti casi:</p>
  <ul class="list-disc ml-20">
    <li>Per rispettare obblighi di legge, norme regolamentari o richieste delle autorità competenti;</li>
    <li>Per proteggere i diritti di TheGINLabs o dei suoi Utenti;</li>
    <li>Per prevenire attività illecite o sospette.</li>
  </ul>
  <p class="ml-10">In ogni caso, TheGINLabs si impegna a proteggere le informazioni personali degli Utenti e a limitare la divulgazione delle stesse a casi strettamente necessari.</p>
  
  <h2 class="font-extrabold mt-10 mb-3">6. Sicurezza delle informazioni personali</h2>
  <p class="ml-10">TheGINLabs si impegna a proteggere le informazioni personali degli Utenti attraverso l'utilizzo di misure di sicurezza adeguate per prevenire la perdita, l'uso improprio o l'alterazione delle informazioni personali.<br> TheGINLabs adotta inoltre procedure interne per garantire la sicurezza delle informazioni personali degli Utenti.</p>
  
  <h2 class="font-extrabold mt-10 mb-3">7. Modifiche all'Informativa Privacy</h2>
  <p class="ml-10">TheGINLabs si riserva il diritto di modificare l'Informativa Privacy in qualsiasi momento. Eventuali modifiche saranno pubblicate sul Sito e gli Utenti verranno informati tramite una comunicazione appropriata.</p>
  
  <h2 class="font-extrabold mt-10 mb-3">8. Contatti</h2>
  <p class="ml-10">Per qualsiasi domanda o richiesta in merito all'Informativa Privacy, gli Utenti possono contattare TheGINLabs attraverso il form di contatto disponibile sul Sito o tramite i seguenti recapiti:</p>
  <ul class="list-disc ml-20">
    <li>Indirizzo: SP72, 73042 Casarano (LE)</li>
    <li>Email: info@theginlabs.com</li>
    <!-- <li>Telefono: +39 1234567890</li> -->
  </ul>
</div>
</template>
