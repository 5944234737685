export default {
    spaces: [
        {
            name: "Innovators Forest",
            images: [
                {
                    src: "tgl/desk.jpg",
                    alt: "Innovation Forest",
                },
            ],
        },
        {
            name: "Privacy Pod",
            images: [
                {
                    src: "tgl/pod2.jpg",
                    alt: "Privacy Pod",
                },
            ],
        },
        {
            name: "Meeting Room",
            images: [
                {
                    src: "tgl/meeting.jpg",
                    alt: "Meeting Room",
                },
            ],
        },
        {
            name: "Chilling Area",
            images: [
                {
                    // src: require("@/assets/imgs/chilling.jpg"),
                    src: "tgl/chilling.jpg",
                    alt: "Coworking8",
                },
                
            ],
        },
    ],
};
