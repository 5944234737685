<template>
    <div id="coworking" class="grid grid-cols-12 my-4">
        <div class="col-span-full rounded-3xl bg-neutral px-10 py-10 text-white">
            <h2
            class="text-white font-sans font-black text-center tracking-widest uppercase 
            text-[2.6rem] md:text-[5.2rem] lg:text-[7rem]"
            >
                COWORKING
            </h2>
            <div class="grid grid-cols-2">
                <div class=" col-span-full lg:col-span-1 px-5 py-5">
                    <figure>
                        <IKImage
                            class="object-center object-cover rounded-2xl"
                            alt="TheGINLabs"
                            path="tgl/TGLIns.jpeg"
                            :lqip="{active:true}"
                            width="580"
                            height="650"

                        />
                    </figure>
                </div>
                <div id="advantages" class="col-span-full lg:col-span-1 text-white lg:px-5 py-5">
                    <!-- Coworking Advantages -->
                    <p class="prose text-white">
                        <span clasS="text-xl md:text-2xl lg:text-3xl uppercase">
                            <b>Vantaggi</b>
                        </span>
                    </p>
                    <ul class="lg:px-5 py-4 text-lg ">
                        <li class="lg:border-l-4 border-primary px-3 py-1 my-3" v-for="adv in coworking_advantages" :key="adv.id">
                            <span class="text-lg"><b>{{ adv.claim }}</b></span><br/> 
                            {{ adv.description }}
                        </li>
                    </ul>
                    <!-- End Coworking Advantages -->
                    <!-- Coworking services -->
                    <p id="services" class="prose text-white mt-10">
                        <span clasS="text-xl md:text-2xl lg:text-3xl uppercase">
                            <b>Servizi</b>
                        </span>
                    </p>
                    <div class="grid grid-cols-2 md:grid-cols-3 w-full mt-3 mt-10">
                        <div
                            v-for="s,i in Object.keys(services)" :key="i"
                            class="2xl:w-[90%] xl:w-[80%] w-[90%] h-[70%] flex flex-col text-center border mb-10 pt-10 pb-10 w-full border-white hover:bg-primary hover:border-none rounded-lg hover:text-white align-middle inline text-lg py-1 px-3 transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110  duration-300"
                            :class="{'xxl:text-right': (i % 2) === 0, 'xxl:text-left': (i % 2) !== 2}"
                        >
                            <div class="mt-auto h-fit">
                                <font-awesome-icon :icon="services[s].icon" size="2x"></font-awesome-icon><br>
                                <p class="mt-5 font-sans tracking-widest uppercase font-bold">{{ services[s].name }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- End Coworking services-->
                </div>
            </div>
            <!-- Pricing -->
            <div id="prices">
                <h2
                class="text-white font-sans font-black text-center tracking-widest uppercase my-5 
                text-[3.5rem] md:text-[5rem]" 
                >
                    TARIFFE
                </h2>
                
                <div class="w-full sm:w-2/3 lg:w-[50%] xl:w-[90%] mx-auto flex flex-col xl:flex-row flex-wrap xl:items-stretch my-4 lg:my-4 justify-center">
                    <div 
                        class="mx-0 sm:mx-5 my-4 
                        xl:pb-0
                        " 
                        v-for="p,i in pricing_plans" :key="i"
                    >
                        <PricePlanCard :planObject="p"></PricePlanCard>
                    </div>
                </div>
            </div>            
            <!-- End pricing -->
            <!-- Spaces -->
            <div id="spaces">
                <h2
                class="text-white font-sans font-black text-center tracking-widest uppercase my-5
                text-[3rem] md:text-[5rem]
                "
                >
                    AMBIENTI
                </h2>
                <div class="grid grid-cols-12">
                    <div  class="group 
                        col-span-full 
                        m-2
                        md:col-span-8 md:col-start-3 
                        lg:col-span-5 lg:odd:col-start-2
                        xl:col-span-5 xl:odd:col-start-2
                        px-2 my-5 lg:my-2 " v-for="s,i in spaces" :key="i">
                        <SpaceCard :spaceObject="s"></SpaceCard>
                    </div>
                </div>
            </div>
            
            <!-- End spaces -->
        </div>
    </div>
</template>

<script>
import SpaceCard from '../common/SpaceCard.vue';
import SpacesData from '../../data/spaces';
import { ServicesData, PlansData } from '../../data/price-and-services';
import PricePlanCard from '../common/PricePlanCard.vue';

export default {
    name: "CoworkingPage",
    data() {
        return {
            ...ServicesData,
            plans: {
                ...PlansData,
            },
            coworking_advantages: [
                {
                    id: 1,
                    claim: "Ambiente collaborativo",
                    description: "Il coworking è un ambiente ideale per incontrare altri professionisti e creare relazioni professionali. Questo tipo di ambiente è perfetto per lo sviluppo di nuove opportunità di lavoro e di networking.",
                },
                {
                    id: 2,
                    claim: "Riduzione dei costi",
                    description: "Rispetto ad avere un ufficio privato, i coworking sono molto più convenienti. Questi spazi sono perfetti sia per chi è all'inizio della carriera e deve quindi sostenere i primi investimenti, sia per chi pur avendo un attività già avviata vuole continuare a mantenere il controllo delle proprie spese scegliendo un costo fisso e flessibile",
                },
                {
                    id: 3,
                    claim: "Miglioramento della produttività",
                    description: "Il coworking è un ambiente molto più produttivo rispetto a lavorare da casa. Questo è dovuto alla natura collaborativa e all'ambiente professionale dello spazio.",
                },
            ],
            pricing_plans: [
                {
                    ...PlansData.pricing_plans.thinker1,
                },
                {
                    ...PlansData.pricing_plans.innovator1,
                },
                {
                    ...PlansData.pricing_plans.innovator_plus_month,
                },
                {
                    ...PlansData.pricing_plans.half_day,
                },
                {
                    ...PlansData.pricing_plans.meeting,
                },
            ],
            ...SpacesData,
        };
    },
    components: { SpaceCard, PricePlanCard },
};
</script>
