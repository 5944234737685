<template>
    <div id="contacts" class="grid grid-cols-12 my-4">
        <div class="col-span-full rounded-3xl bg-base-100 px-10 py-10 text-neutral">
            <h2
            class="font-sans font-black text-center tracking-widest uppercase 
            text-[3.5rem] md:text-[5.3rem] lg:text-[7rem]"
            >
                CONTATTI
            </h2>
            <div class="grid grid-cols-2">
                <div class="col-span-full lg:col-span-1 lg:px-6 py-8 lg:border-r-4 border-primary">
                    <h3 class="font-black font-sans text-4xl mb-4">
                        Scrivici un messaggio!
                    </h3>
                    <form ref="form" class="" @submit.prevent="sendEmail">
                        <div class="grid grid-cols-2">
                            <div class="col-span-full lg:col-span-1 px-2">
                                <label class="label">
                                    <span class="label-text font-sans font-bold">Nome</span>
                                </label>
                                <input 
                                    type="text" 
                                    v-model="name" 
                                    name="name" 
                                    class="input input-bordered focus:input-primary w-full"
                                    id="inputName" 
                                    placeholder=""
                                >
                            </div>
                            <div class="col-span-full lg:col-span-1 px-2">
                                <label class="label">
                                    <span class="label-text font-sans font-bold">Cognome</span>
                                </label>
                                <input type="text" v-model="lastname" name="lastname" class="input input-bordered focus:input-primary w-full" id="inputLastname" placeholder="">
                            </div>
                            <div class="col-span-full px-2">
                                <label class="label">
                                    <span class="label-text font-sans font-bold">Indirizzo E-Mail</span>
                                </label>
                                <input type="text" v-model="email" name="email" class="input input-bordered focus:input-primary w-full" id="inputEmail" placeholder="">
                            </div>
                            <!-- <div class="col-span-1 px-2 my-3">
                                <label class="cursor-pointer label">
                                    <span class="label-text font-sans font-bold">Azienda/Professionista</span>
                                    <input type="text" v-model="company" name="company" class="input input-bordered focus:input-primary w-full" id="inputCompany" placeholder=""> 
                                </label>
                            </div>
                            <div class="col-span-full px-2">
                                <label class="label">
                                    <span class="label-text font-sans font-bold">Nome Azienda/Professionista</span>
                                </label>
                                <input class="input input-bordered focus:input-primary w-full" />
                            </div>
                            <div class="col-span-full px-2" >
                                <label class="label">
                                    <span class="label-text font-sans font-bold">P. IVA</span>
                                </label>
                                <input class="input input-bordered focus:input-primary w-full" />
                            </div> -->
                            <div class="col-span-full px-2">
                                <label class="label">
                                    <span class="label-text font-sans font-bold">Il tuo messaggio</span>
                                </label>
                                <textarea  type="text" rows="10" v-model="message" name="message" class="form-control textarea textarea-bordered focus:textarea-primary w-full" id="inputMessage" placeholder=""></textarea>
                            </div>
                            <div class="col-span-full px-2 mt-3">
                                <button type="submit" class="btn btn-primary text-white w-full">
                                    Invia
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-span-full border-t-4 border-base-200 lg:border-t-0 pt-10 lg:col-span-1 lg:px-8 my-auto">
                    <img :src="require('@/assets/imgs/tgl-logo-v.png')" class="max-h-96 mx-auto " alt="TheGINLabs"/>
                    <div class="w-full mt-5 text-neutral">
                        <div class="text-lg prose">
                            <b>TheGINLabs s.r.l.</b> - <b>P. IVA</b> 05273450758 <br/>
                            <b>Sede Legale</b>: <br/> Via San Domenico 1, 73042 Casarano (Lecce) 
                        </div>
                    </div>
                    <!-- <div class="w-full text-neutral">
                        <div class="text-lg prose">
                            
                        </div>
                    </div> -->
                    <div class="w-full text-neutral">
                        <div class="text-lg prose">
                            <b>E-mail</b>: <a href="#">info@theginlabs.it</a> 
                        </div>
                    </div>
                    <div class="w-full text-neutral">
                        <div class="text-lg prose">
                            <b>Telefono</b>: +39 348 86 81 310 
                        </div>
                    </div>
                    <div class="w-full text-neutral">
                        <div class="text-lg prose">
                            <b>Sede Operativa</b>: <br/> Via San Domenico 1, 73042 Casarano (Lecce) 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<!--  -->
<script>

import emailjs from 'emailjs-com';
// import { required, email } from "@vuelidate/validators";

export default {
    name: 'ContactForm',
    
    data() {
    return {
            // submitted: false,
            email: '',
            name: '',
            lastname: '',
            message: '',
            };
    },

//     validations: {
//       name: { required },
//       lastname: { required },
//       email: { required, email },
//   },

  methods: {

    // handleSubmit() {
    //     this.submitted = true;

    //     this.$v.$touch();
        
    //     if (!this.$v.$invalid) {
    //         this.sendEmail();
    //     }
    // },

    sendEmail() {
      emailjs.sendForm('service_ejt627a', 'template_e4wvcyd', this.$refs.form, '2HBv_BGi0m0VYOFR6', {
        email: this.email,
        name: this.name,
        lastname: this.lastname,
        message: this.message,
      })
        .then((result) => {
            console.log('SUCCESS!', result.text);
        }, (error) => {
            console.log('FAILED...', error.text);
        });
    },
  },

};
</script>
