<template>
    <div class="card border border-base-100 h-full hover:-translate-y-6 ease-in duration-300">
        <div class="h-96 carousel carousel-vertical rounded-box">
            <div class="carousel-item h-full" v-for="ci,i in $props.spaceObject.images" :key="i">
                <!-- <img class="object-cover w-full" :src="ci.src" :alt="ci.alt"/> -->
                <IKImage
                    class="object-cover w-full"
                    :alt="ci.alt"
                    :path="ci.src"
                    :lqip="{active:true}"
                    width="580"
                    height="385"

                />
            </div> 
        </div>
        <div class="card-body">
            <h4 class="text-left text-3xl font-extrabold font-sans uppercase">
                <span class="group-hover:tag">{{ $props.spaceObject.name }}</span>
            </h4>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpaceCard',
    props: {
        spaceObject: {
            type: Object,
            default() {
                return {
                    name: '',
                    images: [],
                    description: '',
                };
            },
        },
    },
};
</script>
