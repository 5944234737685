<template>
    <div class="card shadow-lg h-full hover:-translate-y-6 ease-in duration-300">
        <div class="carousel carousel-vertical rounded-box ">
            <div class="carousel-item w-full" v-for="ci,i in $props.labsObject.images" :key="i">
                <!-- <img class="object-cover w-full" :src="ci.src" :alt="ci.alt"/> -->
                <IKImage
                    class="object-cover w-full"
                    :alt="ci.alt"
                    :path="ci.src"
                    :lqip="{active:true}"
                    width="200"
                    height="200"

                />
            </div> 
        </div>
        <div class="card-body">
            <p class="text-right text-sm font-sans">
                <span >{{ $props.labsObject.date }}</span>
            </p>
            <h4 class="text-left text-3xl font-extrabold font-sans uppercase">
                <span>{{ $props.labsObject.name }}</span>
            </h4>
            <p class="text-left text-md font-sans">
                <span >{{ $props.labsObject.caption }}</span>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EventCard',
    props: {
        labsObject: {
            type: Object,
            default() {
                return {
                    name: '',
                    images: [],
                    caption: '',
                    date: '',
                };
            },
        },
    },
};
</script>
