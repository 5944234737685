<template>
    <div class="w-full">
        <footer class="p-10 justify-items-center w-full content footer bg-neutral text-base-100 mx-auto">
            <div>
                <span class="font-black tag-sm px-8 uppercase text-white mb-3 px-2 text-lg">
                    Servizi
                </span> 
                <router-link  :to="{name: 'home', hash: '#coworking'}" >
                    Coworking
                </router-link>
                <a class="link link-hover">Digital Marketing</a> 
            </div> 
            <div>
                <span class="font-black tag-sm px-8 uppercase bg-primary text-white mb-3 px-2 text-lg">
                    Link
                </span>  
                <!-- <a class="link link-hover">About us</a>  -->
                <router-link  :to="{name: 'home', hash: '#contacts'}" >
                    Contatti
                </router-link>
                <a class="link link-hover" target="_blank" href="https://goo.gl/maps/bb9cDV9k5bBxw3e76?coh=178572&entry=tt">Dove siamo</a> 
            </div> 
            <div class="text-center">
                <span class="font-black tag-sm px-8 uppercase bg-primary text-white mb-3 px-2 text-lg">
                    GDPR
                </span> 
                <router-link  :to="{name: 'privacyPolicy'}" >
                    Privacy policy
                </router-link>
                <router-link  :to="{name: 'cookiePolicy'}" >
                    Cookie policy
                </router-link>
            </div>
        </footer> 
        <footer class="px-10 py-4 border-t footer bg-neutral text-base-100 border-base ">
            <div class="items-center grid-flow-col">
                <div class="bg-white p-2 rounded-lg">
                    <img class="h-14" :src="require('@/assets/imgs/tgl-sm.png')" alt="TGL">
                </div>
                <p class="ml-5">TheGINLabs s.r.l. - P.IVA 05273450758
                <br>Sede Legale: SP 72 Km 1, 73042 Casarano (LE)
                <br>Sede Operativa: Via San Domenico, 73042 Casarano (LE)
                
                </p>
            </div> 
            <div class="md:place-self-center md:justify-self-end">
                <div class="grid grid-flow-col gap-4">
                <a href="https://www.facebook.com/profile.php?id=100086565066254" >
                    <font-awesome-icon class="h-7" icon="fa-brands fa-facebook-f" size="4x"></font-awesome-icon>
                </a> 
                <a href="https://instagram.com/thegin.labs" >
                    <font-awesome-icon class="h-7"  icon="fa-brands fa-instagram" size="4x"></font-awesome-icon>
                </a> 
                <a href="https://www.linkedin.com/company/the-gin-labs/">
                    <font-awesome-icon class="h-7"  icon="fa-brands fa-linkedin" size="4x"></font-awesome-icon>
                </a>
                </div>
            </div>
        </footer>
    </div>
</template>
